import { reactive } from 'vue'

// 固定需要缓存的页面
const FIX_KEEPALIVE = ['home', 'post', 'my']

const cache = reactive({
  exclude: [],
  include: [],
  keepalive: [...FIX_KEEPALIVE]
})

export const addCache = ({ name }) => {
  cache.keepalive.push(name)
}

export const deleteCache = (path) => {
  if (FIX_KEEPALIVE.includes(path)) {
    return null
  }

  const index = cache.keepalive.findIndex((item) => item.name == name)
  cache.keepalive.splice(index, 1)
}

export const clearCache = () => {
  cache.keepalive = []
}

export const resolvePath = ({ to, form }) => {
  // 首先应该判断将进入的路由是返回操作还是前进操作
  // 默认我们在缓存中如果能找到将进入的路由，那么就判定是返回操作
  const isBackAction = cache.keepalive.includes(to.path)

  if (isBackAction) {
    deleteCache(to.path)
    return null
  }

  if (!isBackAction) {
    addCache(to.path)
    return null
  }
}

export { FIX_KEEPALIVE }

export default cache
