// 关于7.5的解释：通用设计稿是750px，分成100份就是每份7.5，那么可设置根fontSize7.5px，
// 那么如果此时一个375px的div可设置他的width为375/7.5=50，这样非常麻烦，不如750px分成7.5份，
// 那么每份就是100，这样375px的div就是375/100=3.75，这样换算就太简单了。
// 当然这个7.5是常量，每个设备的宽度也不是750，但是我们不需要关心最终这个每份的具体px是多少，
// 因为我们只对设计稿进行换算，所以永远都是每份100px，对于每个设备，我们只需要保证分了7.5份，
// 也就是保证了固定的换算比率
const REM_UNIT = 7.5
const MIN_WIDTH = 414 //标准375
const MIN_FONTSIZE = MIN_WIDTH / REM_UNIT

class Rem {
  constructor() {
    const eventName = 'orientationchange' in window ? 'orientationchange' : 'resize'
    window.addEventListener(eventName, this.calcRem, false)
    document.addEventListener('DOMContentLoaded', this.calcRem, false)
  }

  calcRem() {
    const root = document.documentElement

    if (root.clientWidth > 750) {
      root.style.fontSize = MIN_FONTSIZE + 'px'
      root.querySelector('#app').style.width = MIN_WIDTH + 'px'
      root.querySelector('#app').style.margin = '0 auto'
      return null
    }

    const rem = root.clientWidth / REM_UNIT
    root.style.fontSize = rem + 'px'
    root.querySelector('#app').style.width = 'auto'
  }
}

export default new Rem()
