import { httpGet, httpPost } from '@/utils/http'

export function getUserInfo(params, otherParams) {
  return httpGet('/user/getUserInfo', params, otherParams)
}

export function editIsEntrust(params, otherParams) {
  return httpPost('/bcard/editIsEntrust', params, otherParams)
}

/**我发布的人脉圈名片 */
export function getBcardInfo(params, otherParams) {
  return httpGet('/user/getBcardInfo', params, otherParams)
}
/**我发布的人脉圈名片详情 */
export function getUserBcardInfo() {
  return httpGet(`/user/getUserBcardInfo`)
}
/**人脉圈查看记录 */
export function queryBcardLookList(params, otherParams) {
  return httpGet('/user/queryBcardLookList', params, otherParams)
}

/**我发布的合作圈信息 */
export function queryDemandList(params, otherParams) {
  return httpGet('/user/queryDemandList', params, otherParams)
}

/**合作圈查看记录 */
export function queryDemandLookList(params, otherParams) {
  return httpGet('/user/queryDemandLookList', params, otherParams)
}

/**获取投诉客服微信号 */
export function getCsInfo(params, otherParams) {
  return httpGet('/user/getCsInfo', params, otherParams)
}

/**我的收藏 */
export function queryDemandShareList(params, otherParams) {
  return httpGet('/user/queryDemandShareList', params, otherParams)
}

/**支付订单查询 */
export function queryOrder(params) {
  return httpGet('/payment/queryOrder', params)
}

/**获取会员价格 */
export function getVipPrice(vipLevel) {
  return httpGet(`/user/getVipPrice?vipLevel=${vipLevel}`)
}

/**编辑头像昵称 */
export function editApprove(data) {
  return httpPost(`user/editApprove`,data)
}
