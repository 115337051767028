import axios from 'axios'
import perm from './perm'

const request = axios.create({
  baseURL: window.$_baseURL,
  timeout: 60 * 1000 * 10
})

const ABORT_MAP = new Map()

const CREATE_SIGNAL = (config) => {
  const abortController = new AbortController()
  config.signal = abortController.signal
  ABORT_MAP.set(config.url, abortController)
}

const DELETE_SIGNAL = (config) => {
  const abortController = ABORT_MAP.get(config.url)
  abortController && abortController.abort()
  abortController && ABORT_MAP.delete(config.url)
}

const REPLACE_PARAMS = (url, params) => {
  return Object.keys(params).reduce(
    (pre, cur) => {
      const value = params[cur]
      cur[0] == ':' ? (pre[0] = pre[0].replace(cur, value)) : (pre[1][cur] = value)
      return pre
    },
    [url, {}]
  )
}

const WARN_401_TIP = () => {
  let showing = false

  return async () => {
    if (showing) return null
    showing = true
    await window.$_showDialog({ message: '登录已经失效' })
    window.location.href = '/my'
  }
}

const warn401tip = WARN_401_TIP()

request.interceptors.request.use(
  (request) => {
    // prettier-ignore
    const { url, method, data = {}, params = {}, otherParams : { abort } } = request

    const [REST_URL, REST_PARAMS] = REPLACE_PARAMS(url, method === 'get' ? params : data)

    request.url = REST_URL
    request[method === 'get' ? 'params' : 'data'] = REST_PARAMS

    abort && DELETE_SIGNAL(request)
    abort && CREATE_SIGNAL(request)

    request.headers['Authorization'] = window.localStorage.getItem('$_token')
    if(request.url.includes("upload")){
      request.headers['Content-Type'] = "multipart/form-data"
    }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (response) => {
    const { ok } = response.config.otherParams

    switch (response.data.code) {
      case 200:
        ok && window.$_showNotify({ type: 'success', message: response.data.msg })
        return Promise.resolve(response.data)
      case 401:
        perm.remove()
        warn401tip()
        return Promise.resolve(response.data)
      default:
        window.$_showNotify({ type: 'danger', message: response.data.msg })
        return Promise.resolve(response.data)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export function httpGet(url, params = {}, otherParams = {}) {
  return request({ method: 'get', url, params, otherParams })
}

export function httpPost(url, params = {}, otherParams = {}) {
  return request({ method: 'post', url, data: params, otherParams })
}
