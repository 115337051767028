<script>
import { defineComponent, h } from 'vue'
import { NavBar } from 'vant'

export default defineComponent({
  props: {
    title: { type: String, default: void 0 },
    navbar: { type: Object, default: () => ({}) },
    isNavbar: { type: Boolean, default: true },
    packStyle: { type: Object, default: () => ({ padding: '0 0.3rem' }) }
  },

  setup(props, { slots }) {
    const shim = h('div', { style: { height: 'calc(0.6rem + var(--sab))' } })

    const navStyle = { position: 'fixed', top: 0, left: 0, width: '100%', 'z-index': 2000 }

    const navProps = {
      title: props.title,
      leftArrow: true,
      onClickLeft: () => {
        if(window.$_route.value.query.type){
          window.location.href = "/"
          return
        }
        props.clickLeft ? props.clickLeft() : window.$_back()
      }
    }


    const nav = props.isNavbar ? h('div', { style: navStyle }, h(NavBar, { ...navProps, ...props.navbar }, slots)) : null

    const navShim = h('div', { style: { height: 'var(--van-nav-bar-height)' } })

    return () => h('div', { class: 'q-pack', style: props.packStyle }, [nav, navShim, slots.default && slots.default(), shim])
  }
})
</script>

<style lang="scss" scoped>
.q-pack {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--q-background-color);
  --van-border-width: 0;
  --van-nav-bar-icon-color: #333;
  --van-nav-bar-background: var(--q-background-color);
  --van-nav-bar-title-text-color: var(--q-font-color);
}
</style>
