<template>
  <div class="bcard">
    <div class="title">
      <div class="avatar">
        <img :src="store.user.avator" />
      </div>
      <div class="desc">
        <div>
          <span>{{ store.user.baseInfo }}</span>
          <img v-if="store.user.vipLevel" :src="vip()" />
        </div>
        <div>
          <span>联系方式：{{ store.user.contact }} </span>
        </div>
      </div>
    </div>

    <Entrust v-model="store.user.isEntrust" v-if="props.isEntrust === 10"/>

    <div class="dj">
      <div>开启委托对接</div>
      <van-switch :modelValue="props.isEntrust"
                  :active-value="1" :inactive-value="0"
                  @change="onChange" size="0.24rem" />

      <div @click="onKnow">
        <img :src="wen_png" />
        了解委托对接
      </div>
    </div>

    <div class="label" v-if="store.user.identity">{{ store.user.identity }}</div>
  </div>
</template>

<script setup>
import store from '../../store'
import Entrust from './entrust.vue'
import vip from '../../hooks/vip'
import wen_png from '@/assets/images/wen.png'
import { h } from 'vue'
import { showConfirmDialog } from 'vant'
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  isEntrust: { type: Number , default: 10 },
})
const onChange = async (value) => {
  if(store.user.vipLevel < 8){
    showConfirmDialog({
      title: '提示',
      message:'当前功能仅限年度会员使用~',
      confirmButtonText:'去开通'
    }).then(() => {
      window.$_router.push('/member')
    })
    return
  }
  emits('update:modelValue', value)
}
const message = () => {
  const style = 'display:flex;font-size:0.24rem;line-height:0.4rem'
  return h('div', {}, [
    h('div', { style }, '1. 专属客服帮助您进行客户筛选'),
    h('div', { style }, '2. 避免过多打扰，过滤不相符的信息'),
    h('div', { style }, '3. 全天候客户帮助您对接想要的资源'),
    h('div', { style }, '开通委托对接功能后请尽快联系客服')
  ])
}
const onKnow = () => {
  window.$_showDialog({
    title: '委托对接',
    message: message
  })
}

</script>

<style lang="scss" scoped>
.bcard {
  margin-top: 0.24rem;
  background: #fff;
  border-radius: 0.16rem;
  padding: 0 0.2rem;
  position: relative;
  overflow: hidden;

  .title {
    height: 1.72rem;
    display: flex;
    align-items: center;
    border-bottom: var(--q-thin-border);

    .avatar {
      width: 0.97rem;
      height: 0.97rem;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .desc {
      flex: 1;
      margin-left: 0.2rem;
      div:nth-child(1) {
        display: flex;
        align-items: center;

        span {
          width: 4rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;
          font-size: 0.28rem;
          color: var(--q-font-color);
        }
        img {
          margin-left: 0.1rem;
          width: 0.44rem;
        }
      }
      div:nth-child(2) {
        margin-top: 0.14rem;
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        color: var(--q-font-light-color);

        img {
          margin-left: 0.1rem;
          width: 0.44rem;
        }
      }
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
    height: 0.3rem;
    display: flex;
    align-items: center;
    font-size: 0.2rem;
    background: var(--q-success-color);
    padding: 0.02rem 0.04rem;
    border-radius: 0 0.15rem 0.15rem 0;
  }

  .dj {
    height: 0.9rem;
    display: flex;
    align-items: center;

    div:nth-child(1) {
      font-weight: 400;
      font-size: 0.24rem;
      color: var(--q-font-color);
    }

    div:nth-child(2) {
      margin-left: 0.1rem;
    }

    div:nth-child(3) {
      margin-left: auto;
      display: flex;
      align-items: center;
      font-size: 0.2rem;
      color: #0077ff;
      line-height: 0.28rem;

      img {
        width: 0.2rem;
        height: 0.2rem;
      }
    }
  }
}
</style>
