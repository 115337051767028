<template>
  <div class="dj">
    <div>开启委托对接</div>
    <van-switch :modelValue="modelValue" :active-value="1" :inactive-value="0" @change="onChange" size="0.24rem" />
    <div @click="onKnow">
      <img :src="wen_png" />
      了解委托对接
    </div>
  </div>
</template>

<script setup>
import { h } from 'vue'
import { editIsEntrust, getUserInfo } from '../../api/user'
import wen_png from '@/assets/images/wen.png'
import store from '@/store/index.js'
import { showConfirmDialog } from 'vant'

const props = defineProps({
  needSend: { type: Boolean, default: true },
  modelValue: { type: Number || String, default: 0 }
})

const emits = defineEmits(['update:modelValue'])

const message = () => {
  const style = 'display:flex;font-size:0.24rem;line-height:0.4rem'
  return h('div', {}, [
    h('div', { style }, '1. 专属客服帮助您进行客户筛选'),
    h('div', { style }, '2. 避免过多打扰，过滤不相符的信息'),
    h('div', { style }, '3. 全天候客户帮助您对接想要的资源'),
    h('div', { style }, '开通委托对接功能后请尽快联系客服')
  ])
}

const onKnow = () => {
  window.$_showDialog({
    title: '委托对接',
    message: message
  })
}

const onChange = async (value) => {
  if(store.user.vipLevel < 8){
    showConfirmDialog({
      title: '提示',
      message:'当前功能仅限年度会员使用~',
      confirmButtonText:'去开通'
    }).then(() => {
      window.$_router.push('/member')
      })
    return
  }
  // 是否委托(0-否 1-是)
  await editIsEntrust({ isEntrust: value })
  let data = await getUserInfo();
  if(data.code === 200){
    store.user = data.data
    window.localStorage.setItem('$_user', JSON.stringify(data.data))
  }
  emits('update:modelValue', value)
}
</script>

<style lang="scss" scoped>
.dj {
  height: 0.9rem;
  display: flex;
  align-items: center;

  div:nth-child(1) {
    font-weight: 400;
    font-size: 0.24rem;
    color: var(--q-font-color);
  }

  div:nth-child(2) {
    margin-left: 0.1rem;
  }

  div:nth-child(3) {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 0.2rem;
    color: #0077ff;
    line-height: 0.28rem;

    img {
      width: 0.2rem;
      height: 0.2rem;
    }
  }
}
</style>
