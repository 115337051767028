import tool from './tool'
import { showNotify, showToast, showLoadingToast, showSuccessToast, showFailToast, showDialog } from 'vant'

// prettier-ignore
window.$_showNotify = showNotify
window.$_showToast = showToast
window.$_showLoadingToast = showLoadingToast
window.$_showSuccessToast = showSuccessToast
window.$_showFailToast = showFailToast
window.$_showDialog = showDialog

window.$_baseURL = 'http://qjj.pxxpxxpxx.com/yg-api'
// window.$_baseURL = 'http://qjj-test.pxxpxxpxx.com/yg-api'
// window.$_baseURL = '/yg-api'
window.$_appid = 'wx1594877eb6989f7d'
window.$_tool = tool
window.$_init = false
