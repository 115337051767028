import login from '../utils/login'
import { createWebHistory, createRouter ,createWebHashHistory} from 'vue-router'
import { addCache, deleteCache, clearCache } from '../utils/cache'

const routes = [
  { path: '/', redirect: { name: 'home' } },
  { path: '/home', name: 'home', component: () => import('@/views/home/index.vue') },
  { path: '/ren', name: 'ren', component: () => import('@/views/home/ren.vue') },
  { path: '/he', name: 'he', component: () => import('@/views/home/he.vue') }
]

const post = [
  { path: '/post', component: () => import('@/views/post/index.vue') },
  { path: '/bcard', component: () => import('@/views/post/bcard.vue') },
  { path: '/push', component: () => import('@/views/post/push.vue') }
]

const my = [
  { path: '/my', component: () => import('@/views/my/index.vue') },
  { path: '/member', component: () => import('@/views/my/member.vue') },
  { path: '/setting', component: () => import('@/views/my/setting.vue') },
  { path: '/renpost', component: () => import('@/views/my/renpost.vue') },
  { path: '/renview', component: () => import('@/views/my/renview.vue') },
  { path: '/hepost', component: () => import('@/views/my/hepost.vue') },
  { path: '/heview', component: () => import('@/views/my/heview.vue') },
  { path: '/collect', component: () => import('@/views/my/collect.vue') },
  { path: '/xieyi', component: () => import('@/views/my/xieyi.vue') },
  { path: '/zhengce', component: () => import('@/views/my/zhengce.vue') },
  { path: '/member_xieyi', component: () => import('@/views/my/member_xieyi.vue') },
  { path: '/user', component: () => import('@/views/my/user_info.vue') }
]

const router = createRouter({
  history: createWebHashHistory(),
  // history:createWebHistory("/h5/"),
  routes: [...routes, ...my, ...post]
})

router.beforeEach(async (to, from, next) => {
  next()
})

export default router
