(function () {
  const PX750 = () => {
    if (document.documentElement.clientWidth > 750) {
      document.querySelector("#app").style.transform = "scale(1)";
    } else {
      document.querySelector("#app").style.transform = "";
    }
  };

  window.addEventListener("resize", PX750, false);
  window.addEventListener("DOMContentLoaded", PX750, false);
})();