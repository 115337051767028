import './styles/root.scss'
import './styles/global.scss'
import './utils/rem'
import './utils/max'
import './style.css'
import './utils/win'
import router from './router'
import Vant from 'vant'
import App from './App.vue'
import 'vant/lib/index.css'
import QPack from './components/QPack/index.vue'
import QShim from './components/QShim/index.vue'
import QList from './components/QList/index.vue'
import BCard from './components/BCard/index.vue'
// import VConsole from 'vconsole';
import { createApp } from 'vue'
const app = createApp(App)

app.use(router)
window.$_route = app.config.globalProperties.$router.currentRoute
window.$_router = app.config.globalProperties.$router
window.$_push = app.config.globalProperties.$router.push
window.$_back = app.config.globalProperties.$router.back

app.use(Vant)
app.component('QPack', QPack)
app.component('QShim', QShim)
app.component('QList', QList)
app.component('BCard', BCard)

app.mount('#app')



// new VConsole({ theme: 'dark' });
