import store from '../store/index'

const add = async ({ token, needPrefix = true }, api, success, fail) => {
  try {
    store.token = needPrefix ? 'Bearer ' + token : token
    window.localStorage.setItem('$_token', store.token)
    const { data } = await api()
    store.user = data
    window.localStorage.setItem('$_user', JSON.stringify(data))
    success && success(data)
  } catch (error) {
    fail()
  }
}

const remove = () => {
  store.user = ''
  store.token = ''
  window.localStorage.setItem('$_token', '')
  window.localStorage.setItem('$_user', '')
}

export default { add, remove }
