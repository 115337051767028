import vip_y_png from '@/assets/images/vip_y.png'
import vip_m_png from '@/assets/images/vip_m.png'
import store from '../store'

// vipLevel vip等级 0普通用户 1月度会员 8年度会员 9永久会员
const vip_png_data = {
  1: vip_m_png,
  8: vip_y_png
}

export default (vipLevel) => {
  return vip_png_data[vipLevel ?? store.user.vipLevel]
}
