<template>
  <div class="nav" v-if="state.isNav">
    <van-tabbar v-model="state.active" v-bind="tabbarProps" route>
      <van-tabbar-item replace to="/home">
        <span>首页</span>
        <template #icon="props">
          <SvgIcon name="home" width="0.44rem" height="0.44rem" :color="svgColor(props)" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/post">
        <span>发布</span>
        <template #icon="props">
          <SvgIcon name="post" width="0.44rem" height="0.44rem" :color="svgColor(props)" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my">
        <span>我的</span>
        <template #icon="props">
          <SvgIcon name="my" width="0.44rem" height="0.44rem" :color="svgColor(props)" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue'

import { FIX_KEEPALIVE } from '@/utils/cache.js'

import SvgIcon from '@/components/SvgIcon/index.vue'

const svgColor = (props) => (props.active ? '#06AE56' : '#BDC2C9')

const tabbarProps = { 'active-color': '#06AE56', 'inactive-color': '#BDC2C9' }

const { path } = window.$_route.value

const state = reactive({
  active: FIX_KEEPALIVE.findIndex((item) => '/' + item == path),
  isNav: true
})

watch(
  () => window.$_route.value,
  ({ path }) => (state.isNav = FIX_KEEPALIVE.includes(path.replace('/', '')))
)
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  height: 1.2rem;
  transform: scale(1);
  --van-tabbar-height: 100%;
}
</style>
