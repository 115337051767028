<script>
import { List, PullRefresh } from 'vant'
import { defineComponent, h, reactive, computed, watch, nextTick, getCurrentInstance } from 'vue'

export default defineComponent({
  props: {
    pull: { type: Boolean, default: true },
    paging: { type: Boolean, default: true }, // 分页
    api: { type: Function, default: () => {} },
    source: { type: Array, default: () => [] },
    config: { type: Object, default: () => ({}) },
    pullConfig: { type: Object, default: () => ({}) },
    pageNum: { type: Number, default: 1 },
    pageSize: { type: Number, default: 10 }
  },

  setup(props, { slots, expose }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      data: props.source,
      pageNum: props.pageNum,
      pageSize: props.pageSize,
      error: false,
      loading: false,
      finished: false,
      refreshing: false
    })

    watch(
      () => state.data,
      () => {
        nextTick(() => {
          if (!state.finished) return null
          const empty = proxy.$el.querySelector('.van-list__finished-text')
          state.data.length ? empty.classList.remove('empty') : empty.classList.add('empty')
        })
      }
    )

    const cells = computed(() => {
      return state.data.map((row, index) => h(slots.default, { row, index }))
    })

    const restart = () => {
      state.loading = false
      state.pageNum = props.pageNum
      state.pageSize = props.pageSize
      onLoad({ restart: true })
    }

    const onLoad = async (params) => {
      const { pull, restart } = params ?? {}

      state.refreshing = pull
      ;(pull || restart) && (state.finished = false)

      if (state.loading || state.finished) return null

      try {
        state.loading = true

        const params = props.paging ? { pageNum: state.pageNum, pageSize: state.pageSize } : {}

        const { data } = await props.api(params)

        if (props.paging) {
          data.rows.length < state.pageSize && (state.finished = true)
          state.data = pull || restart ? data.rows : state.data.concat(data.rows)
          state.pageNum++
        } else {
          state.data = data
          state.finished = true
        }

        state.error = false
      } catch (error) {
        state.error = true
      } finally {
        state.loading = false
        state.refreshing = false
      }
    }

    const onRefresh = async () => {
      state.loading = false
      state.pageNum = props.pageNum
      state.pageSize = props.pageSize
      onLoad({ pull: true })
    }

    const BASE_PROPS = computed(() => ({
      onLoad,
      finishedText: '没有更多了...',
      error: state.error,
      finished: state.finished,
      loading: state.loading,
      ...props.config
    }))

    const BASE_COMPONENT = () => h(List, BASE_PROPS.value, () => cells.value)

    const FIX_PROPS = computed(() => ({
      onRefresh,
      modelValue: state.refreshing,
      ...props.pullConfig
    }))

    const FIX_COMPONENT = () => h(PullRefresh, FIX_PROPS.value, BASE_COMPONENT)

    expose({ restart })

    return props.pull ? FIX_COMPONENT : BASE_COMPONENT
  }
})
</script>

<style lang="scss" scoped>
:deep(.empty) {
  height: 4rem;
}
</style>
